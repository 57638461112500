import React, { useState, useEffect } from 'react'
import { MultiSelect } from "react-multi-select-component"
import utils from '../../services/utils'
import constants, { SEARCH_PARAM_DEPARTMENT } from '../../constants'
import './ConversationsSearchCriteria.css'
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

const ConversationsSearchCriteria = (props) => {
    const { queues, role, stations , agents,directions} = props
    const [dnis, setDNIS] = useState()
    const [ani, setANI] = useState()
    const [relatedRecordingId, setRelatedRecordingId] = useState();
    const [conversationID, setConversationID] = useState()

    const [selectedStations, setSelectedStations] = useState([])
    const [selectedAgents, setSelectedAgents] = useState([])
    const [selectedDirection, setSelectedDirection] = useState([])
    const [selectedQueues, setSelectedQueues] = useState([])
    const [startSearch, setStartSearch] = useState(false)
    const [dateRange, setdateRange] = useState([]); // 0th position - startDate , 1st position - end Date

    function onSelectDate(dateArray, dateString) {
       
        setdateRange(dateArray);
    }

    // init from session storage
    useEffect(() => {
        try {
            // sessionStorage.setItem(constants.R2S_CONVERSATIONS,'');
           
            const searchCriteriaTemp = JSON.parse(sessionStorage.getItem(constants.R2S_SEARCH_CRITERIA));
           
            if(searchCriteriaTemp){
                try {
                    if(searchCriteriaTemp.startDateTime && searchCriteriaTemp.endDateTime){
                       
                        setdateRange([dayjs(searchCriteriaTemp.startDateTime),dayjs(searchCriteriaTemp.endDateTime)]);
                    }
                    
                    if(searchCriteriaTemp.agent){
                       
                        let tempCriteria ;
                        let selectedAgentTemp ;
                        if(searchCriteriaTemp.agent == '*'){
                            selectedAgentTemp = agents.filter((x => x.value!== '*'));
                        }else{
                            tempCriteria = searchCriteriaTemp.agent.split(',');
                            selectedAgentTemp = agents.filter((x => tempCriteria.includes(x.value)));
                        }
                       
                        setSelectedAgents(selectedAgentTemp);
                        //setEndDate(searchCriteriaTemp.endDateTime);
                    }

                    if(searchCriteriaTemp.direction){
                       
                        if(searchCriteriaTemp.direction == '*' || searchCriteriaTemp.direction == ''){
                            setSelectedDirection(directions.filter((x => x.value !== '*')));
                        }else{
                            setSelectedDirection(directions.filter((x => x.value == searchCriteriaTemp.direction)));
                        }
                    }

                    if(searchCriteriaTemp.queues){
                       
                        let tempCriteria ;
                        let selectedQueuesTemp ;
                        if(searchCriteriaTemp.queues == '*'){
                            selectedQueuesTemp = queues.filter((x => x.value!== '*'));                            
                        }else{
                            tempCriteria = searchCriteriaTemp.queues.split(',');
                            selectedQueuesTemp = queues.filter((x => tempCriteria.includes(x.value)));
                        }
                       
                        setSelectedQueues(selectedQueuesTemp);
                    }

                    if(searchCriteriaTemp.station){
                       
                        let tempCriteria ;
                        let selectedStationsTemp ;
                        if(searchCriteriaTemp.station == '*'){
                            selectedStationsTemp = stations.filter((x => x.value!== '*'));
                        }else{
                            tempCriteria = searchCriteriaTemp.station.split(',');
                            selectedStationsTemp = stations.filter((x => tempCriteria.includes(x.value)));
                        }
                       
                        setSelectedStations(selectedStationsTemp);
                    }

                    if(searchCriteriaTemp.ani){
                        setANI(searchCriteriaTemp.ani);
                    }

                    if(searchCriteriaTemp.dnis){
                        setDNIS(searchCriteriaTemp.dnis);
                    }
                    
                    if(searchCriteriaTemp.conversationID){
                        setConversationID(searchCriteriaTemp.conversationID);
                    }

                    if(searchCriteriaTemp.relatedRecordingId){
                        setRelatedRecordingId(searchCriteriaTemp.relatedRecordingId);
                    }

                   
                } catch (error) {
                    
                }
            }
            if (!searchCriteriaTemp) return;

        } catch (error) {
           
        }
    }, [])

    const search = async () => {
        setStartSearch(true)
        if (!dateRange[0] || !dateRange[1]) return
       
        const searchCriteria = new Map()
        if (dateRange[0]){            
            searchCriteria.set(constants.SEARCH_PARAM_STARTDT, dateRange[0].toISOString());            
        } 
        if (dateRange[1]){            
            searchCriteria.set(constants.SEARCH_PARAM_ENDDT, dateRange[1].toISOString());
        }
    
        if(selectedStations){
           
            if (selectedStations.length === stations.length){ // all
               
                if(role =='admin'){
                    searchCriteria.set(constants.SEARCH_PARAM_STATION, '*')
                }else{
                    searchCriteria.set(constants.SEARCH_PARAM_STATION, decodeURIComponent(stations.map(x => x.value)));
                }
            }else{
                searchCriteria.set(constants.SEARCH_PARAM_STATION, decodeURIComponent(selectedStations.map(x => x.value)));
            }
        }
        if (selectedQueues) {            
            if (selectedQueues.length === queues.length){ // all
                if(role =='admin'){
                    searchCriteria.set(constants.SEARCH_PARAM_QUEUES, '*')
                }else{
                    searchCriteria.set(constants.SEARCH_PARAM_QUEUES, decodeURIComponent(queues.map(x => x.value)));
                }
            }else{
                searchCriteria.set(constants.SEARCH_PARAM_QUEUES, decodeURIComponent(selectedQueues.map(x => x.value)));
            }
        }

        if (selectedAgents) {
            //searchCriteria.set('selectedAgents', selectedAgents);
            if (selectedAgents.length === agents.length) // all
                searchCriteria.set(constants.SEARCH_PARAM_AGENT, '*');
            else
                searchCriteria.set(constants.SEARCH_PARAM_AGENT, decodeURIComponent(selectedAgents.map(x => x.value)));
        }

        if (selectedDirection) {            
            if (selectedDirection.length === directions.length) // all
                searchCriteria.set(constants.SEARCH_PARAM_DIRECTION, '*');
            else
                searchCriteria.set(constants.SEARCH_PARAM_DIRECTION, decodeURIComponent(selectedDirection.map(x => x.value)));
        }
        if (ani) searchCriteria.set(constants.SEARCH_PARAM_ANI, decodeURIComponent(ani));
        if (dnis) searchCriteria.set(constants.SEARCH_PARAM_DNIS, decodeURIComponent(dnis));
        if (conversationID) searchCriteria.set(constants.SEARCH_PARAM_CONVERSATIONID, conversationID);

        if (relatedRecordingId) searchCriteria.set(constants.SEARCH_PARAM_RELATED_RECORDING_ID, decodeURIComponent(relatedRecordingId));

       
        sessionStorage.setItem(constants.R2S_SEARCH_CRITERIA, JSON.stringify(utils.mapToObj(searchCriteria)));
       

        props.search(utils.mapToObj(searchCriteria))
        
    }
    const cancel = async () => {
       
        props.cancel()
    }

    const getDatePickerClassName = () => {
        return !startSearch || (startDate && endDate) ?
            'input-group d-inline-flex w-auto'
            : 'input-group d-inline-flex w-auto date-picker-error'
    }

    return (
        <div className="search-wrap pl-2 pb-2">
            <div className='row justify-content-start'>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Agent Name</span>
                        </div>
                        <MultiSelect className="multi-select agents-multi-select"
                            options={agents}
                            value={selectedAgents}
                            onChange={e => { setSelectedAgents(e) }}
                            labelledBy="Select"
                        />

                    </div>
                </div>                
                <div className='col col-12 col-xl-3' >
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Station</span>
                        </div>
                        <MultiSelect className="multi-select division-multi-select"
                            options={stations}
                            value={selectedStations}
                            onChange={e => { setSelectedStations(e) }}
                            labelledBy="Select"
                        />
                    </div>
                </div>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Queues</span>
                        </div>
                        <MultiSelect className="multi-select queues-multi-select"
                            options={queues}
                            value={selectedQueues}
                            onChange={e => { setSelectedQueues(e) }}
                            labelledBy="Select"
                        />

                    </div>
                </div>
                
            </div>            
            <div className='row justify-content-start'>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">ANI</span>
                        </div>
                        <input className='form-control conversations-custom-control' type='text' value={ani}
                            onChange={e => setANI(e.target.value)} />
                    </div>
                </div>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">DNIS</span>
                        </div>
                        <input className='form-control conversations-custom-control' type='text' value={dnis}
                            onChange={e => setDNIS(e.target.value)} />
                    </div>
                </div>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Conversation ID</span>
                        </div>
                        <input className='form-control conversations-custom-control' type='text' value={conversationID}
                            onChange={e => setConversationID(e.target.value)} />
                    </div>
                </div>
            </div>
            <div className='row justify-content-start'>                    
                <div className='col col-12 col-xl-auto'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Interval *</span>
                        </div>                        
                        <RangePicker size="large" aria-required placement="bottomLeft" 
                            style={{ borderRadius: "5px" }} 
                                value={dateRange}
                                onCalendarChange={onSelectDate} 
                                />
                    </div>
                </div>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Direction</span>
                        </div>
                        <MultiSelect className="multi-select queues-multi-select"
                            options={directions}
                            value={selectedDirection}
                            onChange={e => { setSelectedDirection(e) }}
                            labelledBy="Select"
                        />

                    </div>
                </div>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Related Recording ID</span>
                        </div>
                        <input className='form-control conversations-custom-control' type='text' value={relatedRecordingId}
                            onChange={e => setRelatedRecordingId(e.target.value)} />
                    </div>
                </div>
            </div>
            <br />
            <div className='row justify-content-center d-flex flex-row-reverse bd-highlight'>
                <div className='col col-2 '>
                    <button type="button" className="btn btn-primary btn-space" onClick={search}>Search</button>
                    <button type="button" className="btn btn-secondary btn-space" onClick={cancel}>Cancel</button>
                </div>
            </div>
        </div>


    )


}

export default ConversationsSearchCriteria;
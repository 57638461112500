import React, { useEffect, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { getParameterByName , getUrlParameter} from "../services/utils";
import config from '../config'
import { pureCloudService } from "../services/purecloud";
import constants from '../constants'
import backendAPI from '../services/backend'



const Main = () => {
    const history = useHistory()

    const fetchDisclaimerContent = async () => {
        try {
            const disclaimerResponse = await backendAPI.getDisclaimerContent(tempToken);
            setConsentResponse(disclaimerResponse.consentSettings);
            sessionStorage.setItem(constants.SESSION_KEY_DISCLAIMER_RESPONSE, JSON.stringify(disclaimerResponse.consentSettings))
        } catch (error) {
            setError(`An error occured while fetching data:${JSON.stringify(error.message)}`)
        } finally {
            setIsWaitingModalOpen(false)
        }

    }

    const notifyUserLogin = async (tempToken) => {
        try {
            sessionStorage.setItem(constants.GC_TOKEN, tempToken);
            const notifyLoginResponse = await backendAPI.postUserLogin(tempToken);
            sessionStorage.setItem(constants.SESSION_KEY_USER_LOGIN_RESPONSE, JSON.stringify(notifyLoginResponse))
            sessionStorage.setItem(constants.SESSION_KEY_CONSENTAGREED, true);
            //setLoginResponse(notifyLoginResponse)
            history.push("/conversations");
            
            //history.push("/management/users/");
            
        } catch (error) {
            //setError(`An error occured while fetching data:${JSON.stringify(error.message)}`)
        } finally {
            //setSendingLogin(false)
        }

    }

    useEffect(() => {
        const init = async () => {
            var hash = window.location.hash.substring(1);
            var params = {}
            hash.split('&').map(hk => { 
            let temp = hk.split('='); 
                params[temp[0]] = temp[1] 
            });
            const queryString = window.location.hash
            
            const urlParams = new URLSearchParams(queryString);
            const token = params.access_token;
            const error = params.error;

            let environment = new URLSearchParams(window.location.search).get("environment");
            let sessionEnvironment = sessionStorage.getItem(constants.GC_ENVIRONMENT);
            if(sessionEnvironment){
                // do nothing
                environment = sessionEnvironment;
            }else{
                if(environment){
                    sessionStorage.setItem(constants.GC_ENVIRONMENT,environment);
                }else{
                    sessionStorage.setItem(constants.GC_ENVIRONMENT,config.defaultEnvironment);
                }
            }

            if (token){
                sessionStorage.setItem(constants.GC_TOKEN,getParameterByName(constants.GC_ACCESS_TOKEN));
                //fetchDisclaimerContent();
                notifyUserLogin(token);
                //history.push({ pathname: '/disclaimer', state: { token: token } })
                
            }else{
                history.push('/login');
            }
            

            if (getParameterByName(constants.GC_ACCESS_TOKEN)) {
                sessionStorage.setItem(constants.GC_TOKEN,getParameterByName(constants.GC_ACCESS_TOKEN));
            }
           if (sessionStorage.getItem(constants.GC_TOKEN)) {
                try {

                    const loggedInUser = await pureCloudService.getCurrentUserDetails(
                        sessionStorage.getItem(constants.GC_ENVIRONMENT),
                        sessionStorage.getItem(constants.GC_TOKEN)
                    );
                    const divisionId = loggedInUser.division.id;
                    sessionStorage.setItem(constants.GC_DIVISION_ID, divisionId);

                    const roles = await pureCloudService.getRoles(
                        sessionStorage.getItem(constants.GC_ENVIRONMENT),
                        sessionStorage.getItem(constants.GC_TOKEN),
                        loggedInUser.id
                    );

                    history.push("/conversations");

            }catch(error){
                if (error.status === 401) {
                    //history.push("/login");
                }else{
                    history.push("/unauthorized", error.message);
                } 
            }
        }else{
               // history.push("/login");
        }};
        init()
    }, [history])

    return (<Fragment></Fragment>)
}

export default Main
import constants from './constants' 
// private config
const development = {
    endpoints: {
        backend: 'https://domesticgen.dev.api.r2s.genesysinnoapps.net',
    },
    clientID: constants.GC_CLIENT_ID,
    integrationType: constants.R2S_INTEGRATION_TYPE,
    appName: "Genesys Recording as a Service (R2S) - Dev",    
    defaultEnvironment: constants.GC_ENV_EU_WEST_2,
    defaultLangTag: constants.GC_LANG_TAG,
    userRole : constants.R2S_USER_ROLE_DEV
}

const production = {
    endpoints: {
        backend: 'https://domesticgen.api.r2s.genesysinnoapps.com',
    },
    clientID: constants.GC_CLIENT_ID,
    integrationType: constants.R2S_INTEGRATION_TYPE,
    appName: "Genesys Recording as a Service (R2S)",
    defaultEnvironment: constants.GC_ENV_EU_WEST_2,
    defaultLangTag: constants.GC_LANG_TAG,
    userRole : constants.R2S_USER_ROLE_PROD
}

const qa = {
    endpoints: {
        backend: 'https://domesticgen.test.api.r2s.genesysinnoapps.net',
    },
    clientID: constants.GC_CLIENT_ID,
    integrationType: constants.R2S_INTEGRATION_TYPE, 
    appName: "Genesys Recording as a Service (R2S) QA",
    defaultEnvironment: constants.GC_ENV_EU_WEST_2,
    defaultLangTag: constants.GC_LANG_TAG,
    userRole : constants.R2S_USER_ROLE_TEST
}

const env = process.env.REACT_APP_CUSTOM_ENV.trim()

let config
switch (env) {
    case constants.REACT_ENV_PROD: config = production; break;
    case constants.REACT_ENV_DEV: config = development; break;
    case constants.REACT_ENV_TEST: config = test; break;
    default: break;
}

export default config
